import {
    Form,
    Input,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";
import { BranchOffice } from "interfaces";

export const BranchOfficesMunicipiesForm: React.FC<MyFormProps> = ({formProps}) => {
    const { selectProps } = useSelect<BranchOffice>({
        resource: "v1/branch-office-place",
        optionLabel: "AreaDescription",
        optionValue: "AreaId",
        hasPagination: true,
      });

    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label="Municipio"
                name="Municipio"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select {...selectProps} placeholder="selecione uma filial" />
            </Form.Item>
        </Form>
    );

}