import {
    Space,
    Button,
    Card,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import { useApiUrl, useForm, useNotification } from "@pankod/refine-core";
import { BFF_KEY } from "environment";
import { IBranchOfficesSearch, BranchOffice } from "interfaces";
import { useState } from "react";

type Props = {
    branchOffice?: BranchOffice;
    closeModal: () => void;
    refreshTable: () => void;
}

export const ModalBranchOfficeMunicipiesBind: React.FC<Props> = ({ branchOffice, closeModal, refreshTable }) => {
    const { selectProps } = useSelect<IBranchOfficesSearch>({
        resource: "v1/branch-office-place/list-by-desc",
        optionLabel: "PlaceDescription",
        optionValue: "Id",
        hasPagination: false,
        onSearch(value) {
            return [
                {
                    field: 'place_desc',
                    operator: 'eq',
                    value: value,
                }
            ]
        },
    });
    const [municipieId, setMunicipieId] = useState<number>();

    const toast = useNotification();
    const apiUrl = useApiUrl();
    const handleBindMunicipieToFilial = async () => {
        if (!municipieId) {
            toast.open?.({
                message: 'selecione um municipio para vincular a filial',
                type: 'error',
            });
            return;
        }
        await fetch(`${apiUrl}/v1/branch-office-place/${municipieId}/update-areaid/${branchOffice?.AreaId}`, {
            method: 'PUT',
            headers: {
                'ocp-apim-subscription-key': BFF_KEY,
            },
        }).then(() => {
            toast.open?.({
                message: 'filial vinculada ao municipio com sucesso.',
                type: 'success',
            });
        }).catch(() => {
            toast.open?.({
                message: 'ocorreu um erro ao tentar vincular o municipio a filial selecionada, tente novamente mais tarde.',
                type: 'error',
            });
        }).finally(() => {
            refreshTable();
            closeModal();
        });
    };

    return (
        <>
            <Card title="Selecione um Municipio">
                    <Space>
                        <Select 
                            {...selectProps} 
                            onSelect={(data) => setMunicipieId(data as unknown as number)} 
                            style={{ width: '30rem' }} 
                        />
                        <Button
                            type="primary"
                            onClick={handleBindMunicipieToFilial}
                        >
                            Vincular
                        </Button>
                    </Space>
            </Card>
        </>
    );
}